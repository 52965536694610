<template>
  <div class="reward-requests-container">
    <CCard>
      <CCardHeader>
        <h1>{{$t('REWARD_REQUEST_PAGE_TITLE')}}</h1>
      </CCardHeader>
      <CCardBody>
        <SearchRewardRequestsHeader
          :onSearch="onSearch"
        />
        <RewardRequestListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
          :isAllowedViewDetails="isAllowedViewDetails"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { mapState, mapActions, mapGetters } from 'vuex';
import RewardRequestListTable from '@/components/RewardRequest/RewardRequestListTable';
import SearchRewardRequestsHeader from '@/components/RewardRequest/SearchRewardRequestsHeader';
import StakingPagination from '@/components/StakingPagination';
import { endpoints, env } from '@/constants';
import responseHelper from '@/utils/response-helper';
import { ClaimRequestStatus } from '@/enums';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'RewardRequestList',
  components: {
    RewardRequestListTable,
    SearchRewardRequestsHeader,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      pageInput: 1,
      isAllowedApprove: false,
      isAllowedViewDetails: false,
      selectedItem: null,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_VIEW_CACULATOR_REWARD_REQUEST_DETAIL') {
            this.isAllowedViewDetails = true;
          }
        });
      }
    },
    async getCaculateRewardRequests(data) {
      data = data || {};

      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          email: filterData.email,
          from_date: filterData.fromDate,
          to_date: dateTimeHelper.adjustToDate(filterData.toDate),
          currency: this.checkValueOption(filterData.currency),
          status: this.checkValueOption(filterData.status),
        };
        const result = await this.$http.get(endpoints.getCaculateRewardRequests, { params });

        const { isAllowedApprove, isAllowedReject } = this;
        this.items = result.data.items || [];

        this.total = result.data.total;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    checkValueOption(val){
      if (val == 'all'){
        return '';
      };
      return val;
    },
    async onSearch(searchData) {
      const data = searchData || sessionStorageHelper.getData('filter-caculate-reward');
      this.activePage = 1;
      this.pageInput = 1;

      this.getCaculateRewardRequests(data);
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getCaculateRewardRequests();
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getCaculateRewardRequests();
    },
  },
};
</script>
<style lang="scss">
.reward-requests-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }
  }
}
</style>
