var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CCard',{staticClass:"reward-request-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":"","pagination":"","itemsPerPage":_vm.limit},scopedSlots:_vm._u([{key:"#",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_vm._v(_vm._s(index + 1 + (_vm.activePage-1)*_vm.limit))])]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingCurrency',{attrs:{"value":item.amount,"currencySymbol":item.currency_symbol}})],1)]}},{key:"from_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")(item.from_date,_vm.dateFormat)))])]}},{key:"to_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm._f("moment")(item.to_date,_vm.dateFormat)))])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"reward-requests-status","text":item.status}})],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-right"},[(_vm.isAllowedViewDetails)?_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }