<template>
  <CCard class="reward-request-list-table-card">
    <CDataTable :items="items" :fields="fields" fixed hover striped bordered pagination :itemsPerPage="limit">
      <template ##="{item, index}">
        <td>{{index + 1 + (activePage-1)*limit}}</td>
      </template>

      <template #amount="{item}">
        <td>
          <StakingCurrency :value="item.amount" :currencySymbol="item.currency_symbol" />
        </td>
      </template>

      <template #from_date="{item}">
        <td class="text-nowrap">{{item.from_date | moment(dateFormat)}}</td>
      </template>

      <template #to_date="{item}">
        <td class="text-nowrap">{{item.to_date | moment(dateFormat)}}</td>
      </template>
      <template #status="{item}">
        <td>
          <StakingBadge type="reward-requests-status" :text="item.status" />
        </td>
      </template>

      <template #action="{item}">
        <td class="text-right">
          <button
            class="btn btn-view-details"
            v-if="isAllowedViewDetails"
            @click="viewDetails(item)"
          >
            <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
          </button>
        </td>
      </template>
    </CDataTable>
  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingCurrency from '@/components/StakingCurrency';
import { Constants } from '@/constants';
import StakingBadge from '@/components/StakingBadge';

export default {
  name: 'RewardRequestListTable',
  components: {
    StakingCurrency,
    StakingBadge,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: '#',
          label: this.$t('REWARD_REQUEST_LIST_TABLE_COMPONENT_LABEL_NUMBER'),
        },
        {
          key: 'id',
          label: this.$t('REWARD_REQUEST_TABLE_COMPONENT_COL_ID'),
        },
        {
          key: 'currency_symbol',
          label: this.$t('REWARD_REQUEST_TABLE_COMPONENT_COL_CURRENCY'),
        },
        {
          key: 'from_date',
          label: this.$t('REWARD_REQUEST_TABLE_COMPONENT_COL_FROM_DATE'),
          _style: 'width: 180px'
        },
        {
          key: 'to_date',
          label: this.$t('REWARD_REQUEST_TABLE_COMPONENT_COL_TO_DATE'),
          _style: 'width: 180px'
        },
        {
          key: 'affiliate_type',
          label: this.$t('REWARD_REQUEST_TABLE_COMPONENT_COL_AFFILIATE_TYPE'),
            _style: 'width: 180px'
        },
        {
          key: 'status',
          label: this.$t('REWARD_REQUEST_TABLE_COMPONENT_COL_STATUS'),
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    isAllowedViewDetails: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`reward-requests/${item.id}/details`);
    },
  },
};
</script>

<style lang="scss">
.reward-request-list-table-card {
  .table {
    margin-bottom: 0;
    .membership-order-status {
      background: #62d935;
      color: #fff;
    }
    .hand {
      cursor: pointer;
    }
    .btn {
      font-size: 14px;
      font-weight: 400;
      color: #0505a5;
      outline: none;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
</style>
