<template>
  <div class="search-reward-requests-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_REWARD_REQEUST_COMPONENT_LABEL_FROM"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_REWARD_REQEUST_COMPONENT_LABEL_EMAIL')"
              v-model="searchData.email"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_REWARD_REQEUST_COMPONENT_LABEL_TO"
            v-slot="{ errors }"
            rules
          >
            <div role="group" class="form-group">
              <label class>{{$t("SEARCH_REWARD_REQEUST_COMPONENT_LABEL_DATE_RANGE")}}</label>
              <div class="input-group1">
                <date-range-picker
                  v-model="searchData.dateRange"
                  :locale-data="dateRangeOption.localeData"
                  :always-show-calendars="dateRangeOption.alwaysShowCalendars"
                  :append-to-body="dateRangeOption.appendToBody"
                  :ranges="dateRangeOption.showRanges"
                  :auto-apply="dateRangeOption.autoApply"
                ></date-range-picker>
              </div>
            </div>
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_REWARD_REQEUST_COMPONENT_LABEL_CURRENCY"
            v-slot="{ errors }"
            rules
          >
            <CSelect
              :label="$t('SEARCH_REWARD_REQEUST_COMPONENT_LABEL_CURRENCY')"
              :value.sync="searchData.currency"
              :options="options"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{$t('SEARCH_REWARD_REQEUST_COMPONENT_LABEL_STATUS')}}</label>
            <select v-model="searchData.status" class="form-control">
              <option value>{{$t('SEARCH_REWARD_REQEUST_COMPONENT_OPTION_ALL')}}</option>
              <option
                v-for="item in rewardRequestStatuses"
                :key="item.value"
                :value="item.value"
              >{{item.label}}</option>
            </select>
          </div>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <CCol md="6" sm="12"></CCol>

      <CCol md="3" sm="12">
        <CButton
          class="btn btn-reset"
          @click="resetForm()"
        >{{$t("SEARCH_REWARD_REQEUST_COMPONENT_RESET_BUTTON")}}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton
          class="btn btn-search"
          @click="search()"
        >{{$t('SEARCH_REWARD_REQEUST_COMPONENT_BUTTON_SEARCH')}}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from 'moment';
import { getCryptoPlatformTypes } from '@/enums';
import { Constants } from '@/constants';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import DateRangePicker from 'vue2-daterange-picker';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'SearchRewardRequestsHeader',
  components: { DateRangePicker },
  data() {
    return {
      searchData: sessionStorageHelper.getData('filter-caculate-reward') || this.getDefaultValues(),
      options: [],
      rewardRequestStatuses: [],
      dateRangeOption: {
        alwaysShowCalendars: true,
        appendToBody: true,
        showRanges: false,
        autoApply: false,
        localeData: {
          firstDay: 1,
          format: 'yyyy/mm/dd',
          direction: 'ltr',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
        },
      },
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
  },
  async mounted() {
    await this.getStakingCurrencyList();
    this.options.unshift({ label: this.$t('SEARCH_REWARD_REQEUST_COMPONENT_OPTION_ALL'), value: 'all' });
    this.rewardRequestStatuses = await this.getCaculateRewardRequestStatus();

    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        email: '',
        currency: '',
        status: '',
        dateRange: {
          startDate: null,
          endDate: null,
        },
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();

      this.search();
    },
    async getCaculateRewardRequestStatus() {
      try {
        const res = await this.$http.get(endpoints.getCaculateRewardRequestStatus);
        let options = res.data;
        options = stringHelper.translateTextForDropdownList(options, 'REWARD_REQUESTS_STATUS');
        return options;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('SEARCH_REWARD_REQEUST_COMPONENT_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async search() {
      this.searchData.dateRange.startDate = dateTimeHelper.startOfDate(this.searchData.dateRange.startDate);
      this.searchData.dateRange.endDate = dateTimeHelper.startOfDate(this.searchData.dateRange.endDate);
      sessionStorageHelper.setData('filter-caculate-reward', this.searchData);

      const searchData = {
        ...this.searchData,
        fromDate: this.searchData.dateRange.startDate,
        toDate: this.searchData.dateRange.endDate,
      };

      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.onSearch(searchData);
      });
    },
    async getStakingCurrencyList() {
      try {
        const result = await this.$http.get(endpoints.getStakingCurrencyList);
        this.options = result.data.map(item => {
          return {
            ...item,
            value: item.currency_symbol,
            label: item.name,
          };
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('SEARCH_REWARD_REQEUST_COMPONENT_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.search-reward-requests-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .group-fields {
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
  }
  label {
    display: inline-block;
  }
  .form-group {
    margin-top: 10px;
    margin-bottom: 5px;
    .col-sm-9 {
      input {
        display: inline-block;
        width: 30%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        margin: 0 0 15px 15px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        background-clip: padding-box;
        border: 1px solid;
        color: #768192;
        background-color: #fff;
        border-color: #d8dbe0;
        border-radius: 0.25rem;
        -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      }
    }
    .vue-daterange-picker {
      width: 100%;
      .form-control {
        padding: 7px 15px;
        > i {
          display: none;
        }
        span {
          font-size: 14px;
          font-weight: 300;
          letter-spacing: -0.34px;
        }
      }
    }
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }

  .error-msg {
    color: #ff0000;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
}
</style>
